<template>
    <psi-form-layout-section
        hide-actions
        title="My Details"
        icon="mdi-account-details"
        btn-label="Save Details"
        btn-icon="mdi-account-details"
    >
        <psi-detail-card flat :items="items" hide-heading></psi-detail-card>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "my-details",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: {},
            fields: [],
            items: [],
        };
    },
    computed: {},
    watch: {
        user: {
            immediate: true,
            handler(user) {
                if (Object.keys(user).length > 0) {
                    this.items = [
                        {
                            icon: this.$config("icons.id"),
                            label: "ID",
                            text: user.id,
                            copy: true,
                        },
                        {
                            icon: this.$config("icons.name"),
                            label: "Name",
                            text: user.name,
                        },
                        {
                            icon: this.$config("icons.company"),
                            label: "Company",
                            text: this.$_.get(
                                user,
                                "company.name",
                                ""
                            ).toUpperCase(),
                        },
                        {
                            icon: this.$config("icons.email"),
                            label: "Email",
                            text: user.email,
                        },
                        {
                            icon: this.$config("icons.phone"),
                            label: "Phone",
                            text: this.$_.get(user, "contact.phone", ""),
                        },
                        {
                            icon: this.$config("icons.created"),
                            label: "Created",
                            text: user.created_at.split(" ").shift(),
                        },
                        {
                            icon: this.$config("icons.password_expires"),
                            label: "Password Expires",
                            text: `${user.password_expires_in_days} days from now`,
                        },
                    ];
                }
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update() {},
    },
};
</script>

<style scoped>
</style>