var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        "hide-actions": "",
        title: "My Details",
        icon: "mdi-account-details",
        "btn-label": "Save Details",
        "btn-icon": "mdi-account-details"
      }
    },
    [
      _c("psi-detail-card", {
        attrs: { flat: "", items: _vm.items, "hide-heading": "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }